/* eslint-disable
    consistent-return,
    func-names,
    implicit-arrow-linebreak,
    max-len,
    no-multi-assign,
    no-param-reassign,
    no-return-assign,
    no-sequences,
    no-shadow,
    no-unused-vars,
    no-var,
    operator-linebreak,
    prefer-const,
    prefer-rest-params,
    vars-on-top,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS104: Avoid inline assignments
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
let Garages;
module.exports = (Garages = {});

const moment = require('moment-timezone');
const _ = require('lodash');
const parallel = require('async/parallel');
const URI = require('urijs');
const naturalCompare = require('string-natural-compare');
const Http = require('react/utils/http');
const Str = require('react/utils/str');
const Time = require('react/utils/time');
const Assert = require('react/utils/assert');
const Format = require('react/utils/format');
const DateRange = require('react/utils/date_range');
const Misc = require('react/utils/misc');
const SmkNotice = require('react/utils/smk_notice');
const Localization = require('shared/utils/localization').default;


const BENCHMARK_NAME_IDENTIFIER = /^Industry Benchmark/;
Garages.allGarageUrl = 'all__all';
Garages.portfolioViewName = 'Portfolio View';
Garages.portfolioViewDisplayName = 'Home';

// For most up-to-date number, see `ds/routes/v3/dump.py`. TODO (WW) use API to retrieve it.
Garages.maxDownloadTransactions = 30000;


Garages.legacyGarageId2Url = (cid, gid) => `${cid}__${gid}`;

Garages.garageUrl2legacyIds = function (url) {
  let cid;
  let gid;
  let
    ref;
  return [cid, gid] = Array.from(ref = url.split('__')), ref;
};

// TP: This function definitely needs GraphQL refactoring.
//
// When two arguments: (garageUrl, callback)
// When three arguments: (garageUrl, opts, callback)
//
// opts:
// - garages (*true): Include garages
// - minMaxHour (*true): Include minMaxHour
// - osSystems (*false): Include online sales ids
// - homeDomain (*false): Include homeDimension domain.
//
// ########################################
// Returned profile contains following keys:
//
// Basic:
//   description, address1, address2, city, state, zip
//   bizStartHour, inlotCutoffHours, timezone
//   latitude, longitude
//   contact, contactEmail, contactPhone, contactPhoneType
//   am, engineer
//   name
//   spaces: 683,
//   tag: "vpne",
//   url: "vpne__cceast",
//   pseudo: false,
//   dimensions: [ dimension ]
//     dimension: { name, sql }
//   homeDimension
//   homeGroups: [ group ]
//     group: { id, name }
//
// When opts.garages = true
//   garages: [ garage ]
//
// When minMaxHour = true
//   minTime, maxTime, apiMaxDate (contains prediction period)
//
// When osSystems = true
//   osSystems: { osSystemId: osSystem }
//       osSystem: { system, onlinesalesId, nearbyAirportCode }
//
// When homeDomain = true
//   homeDomain: [ categoryName ]
//
Garages.getGarageProfile = function () {
  let [garageUrl, opts, callback] = Array.from(arguments);
  if (arguments.length === 2) {
    callback = opts;
    opts = {};
  }

  opts = _.defaults(opts, {
    minMaxHour: true,
    osSystems: false,
    homeDomain: false
  });
  opts.garages = true; // always get profile

  const [cid, gid] = Array.from(Garages.garageUrl2legacyIds(garageUrl));
  const urlDefs = {
    garages: `/api/users/v1/garages/${garageUrl}`,
    osSystems: `/api/users/v1/garages/${garageUrl}/onlinesales-profile`
  };

  const urls = _.pickBy(urlDefs, (url, key) => opts[key]);

  return Http.getAll(urls, (results) => {
    const profile = results.garages;
    profile.legacyIds = [cid, gid];

    if (opts.osSystems) {
      profile.osSystems = _.keyBy(results.osSystems.value, 'system');
    }

    // These must be run after initial profile is received
    const allSecondaryTasks = {
      minMaxHour(done) {
        return Garages.getMinMaxTime(profile, ({minTime, maxTime}) => {
          // Here maxTime is the last transaction Date.
          _.extend(profile, Garages.getMinMaxProps(profile, minTime, maxTime));
          return done();
        });
      },
      homeDomain(done) {
        return Garages.getHomeDomain(profile, (homeDomain) => {
          profile.homeDomain = homeDomain;
          return done();
        });
      }
    };

    const usedSecondaryTasks = _.pickBy(allSecondaryTasks, (task, key) => opts[key]);

    return parallel(usedSecondaryTasks, (err, results) => callback(profile));
  });
};


Garages.getNewGarage = (properties) => _.extend({
  tag: '',
  engineer: '',
  am: '',
  salesforceProjectKey: '',
  unicorn: true,
  spaces: 0,
  timezone: moment.tz.guess(),
  bizStartHour: 0,
  inlotCutoffHours: 24,
  transactionOn: 'exit',
  react: true,
  realtime: true,
  internal: false,
  virtual: false,
  locationType: '',
  tFilter: '',
  otFilter: ''
}, properties);


Garages.getProfileById = (garageId, callback) => Http.get(`/api/users/v1/garages/id/${garageId}`, callback);

Garages.getMinMaxProps = (profile, minTime, maxTime) => // LATER TP: Phase away minTime & maxTime, remove them when there's no reference to them.
  ({
    minTime,
    maxTime,
    apiMinDate: minTime,
    apiMaxDate: _getApiMaxDate(profile, maxTime)
  });

// profile: { id, ... }
// callback: (data)
//   data: { minTime, maxTime }
Garages.getMinMaxTime = function (profile, callback) {
  const url = `${Garages.dsPrefix}/${profile.id}/min-max-time`;
  return Http.get(url, {expiration: 60}, (result) => {
    const minTime = moment.utc(result.minTime).toDate();
    const maxTime = moment.utc(result.maxTime).toDate();
    return callback({minTime, maxTime});
  });
};

// callback: (homeDomain)
//   homeDomain: [ categoryName ]
Garages.getHomeDomain = function (profile, callback) {
  const {id, homeDimension} = profile;
  const url = `${Garages.dsPrefix}/${id}/domain?gb=${homeDimension}`;
  return Http.get(url, (result) => callback(result.value));
};

// result: apiMaxDate
//
var _getApiMaxDate = function (profile, lastTransactionDate) {
  const {timezone, bizStartHour} = profile;
  return Time.bizDayStart(Time.now(timezone).add(1, 'month'), bizStartHour);
};

Garages.getGarageProfiles = function (garageUrls, callback) {
  const profiles = [];
  let count = 0;

  return _.flatten(_.map(garageUrls, (url, idx) => Garages.getGarageProfile(url, (profile) => {
    count += 1;
    profiles[idx] = profile;
    if (count === garageUrls.length) {
      return callback(profiles);
    }
  })));
};

Garages.getSortedFilteredGarages = function (garages, query) {
  const sortedGarages = Garages.getSortedGarages(garages);
  return _.filter(sortedGarages, (g) => Str.match(g.name, query));
};

Garages.getSortedGarages = function (garages) {
  garages = _.clone(garages);
  return garages.sort(Garages.compareLocationNames);
};

Garages.compareLocationNames = function (a, b) {
  if (a.url === Garages.allGarageUrl) {
    return -1;
  }
  if (b.url === Garages.allGarageUrl) {
    return 1;
  }
  return naturalCompare(a.name, b.name);
};

Garages.getUserGaragesForPortfolioview = function (user) {
  const locations = Garages.getLocationsWithoutMetaLocation(user.garages);
  if (user.superAdmin) {
    return _.filter(locations, (g) => g.tag === 'gotham');
  }
  const locationsWithoutBenchmark = _.filter(locations, (g) => !Garages.isBenchmarkSegmentationlocation(g));
  return locationsWithoutBenchmark;
};

Garages.getDashboardLocations = (locations) => _.filter(locations, (l) => !Garages.isPortfolioLocation(l) && !l.isMap && !Garages.isBenchmarkSegmentationlocation(l));

Garages.getLocationsWithoutMetaLocation = (locations) => _.filter(locations, (l) => !Garages.isPortfolioLocation(l) && !Garages.isBenchmarkSegmentationlocation(l));

Garages.isPortfolioLocation = (location) => location.name === Garages.portfolioViewName;

Garages.isBenchmarkSegmentationlocation = (location) => BENCHMARK_NAME_IDENTIFIER.test(location.name);

// callback(parkerGroupsData)
//   parkerGroupsData: { parkerGroups: [parkerGroup], lastViewedDimension: str/null }
//       parkerGroup: { dimensionName, groups: [group] }
//           group: { name, cardholders, allocatedSpaces, hidden }
Garages.getParkerGroupsData = function (gid, callback) {
  const url = `/api/users/v1/garages/${gid}/parker_groups`;
  return Http.get(url, (parkerGroupsData) => callback(parkerGroupsData));
};

Garages.updateParkerGroups = function (gid, parkerGroups) {
  const url = `/api/users/v1/garages/${gid}/parker_groups`;
  return Http.post(url, parkerGroups);
};


// callback(budgets)
//   budgets: [ budgetsForYear ]
//       budgetsForYear: { year: int, budgets: [int] }
Garages.loadBudgets = function (gid, callback) {
  const url = `/api/users/v1/garages/${gid}/budgets`;
  return Http.get(url, (result, errMsg) => callback(result.budgets));
};

Garages.updateBudgets = function (gid, budgets, callback) {
  const url = `/api/users/v1/garages/${gid}/budgets`;
  return Http.put(url, budgets, {forceCallback: true}, callback);
};


// Test if garage matches given spec.
//
// spec:
//   urls: [ urlRegex ]  # If at least 1 urlRegex is matched, match = true
//
// result:
//   match: Bool
//   reason: String (Only when match = false)
//
Garages.match = function (garageUrl, spec) {
  const {urls} = spec;

  const unMatchReasons = [];

  if (urls) {
    const matchesOne = _.some(urls, (urlRegex) => garageUrl.match(urlRegex));
    if (!matchesOne) {
      unMatchReasons.push("Garage url doesn't match");
    }
  }

  if (unMatchReasons.length > 0) {
    return {match: false, reason: unMatchReasons.join(', ')};
  }
  return {match: true};
};


Garages.getAllGarages = function (callback) {
  const url = '/api/users/v1/garages';
  return Http.get(url, (data) => callback(data.garages));
};


// Get dateRange using given timeRange, considering the garage's local
// information.
Garages.getDateRange = function (timeRange, profile) {
  Assert.hasAll(profile, ['timezone', 'bizStartHour']);
  const {timezone, bizStartHour, minTime, maxTime} = profile;

  return new DateRange({
    timeRange,
    timezone,
    bizStartHour,
    apiMinDate: minTime,
    apiMaxDate: maxTime
  });
};

// callback: func(data)
//   data: [ group ]
//     group: { id, name, value }
//
Garages.getCurrentOccupancyData = function (garageId, dimension, callback) {
  Assert.validGarageId(garageId);

  const baseUrl = `${Garages.dsPrefix}/${garageId}/current/occupancy`;
  const url = URI(baseUrl).query({gb: dimension}).toString();
  return Http.get(url, (result) => {
    // result: { value: [ group ] }
    //   group: { group: 'Group Name', value: int }

    // groups: [{ group: 'Group Name', value: int }]
    const groups = Garages.formatResponse(result);
    const data = _.map(groups, (g) => ({
      id: g.group,
      name: g.group,
      value: g.value
    }));

    return callback(data);
  });
};

Garages.getKeyStatsThisYearLastYear = require('./getKeyStatsThisYearLastYear').default;

// Extract value from a single Total group or return NaN if no data
// data: { value: [totalGroup] or [] }
//   totalGroup: { group: 'Total', value: intValue }
// dataType is only used for debugging
// Output: intValue
Garages.getTotalGroupValue = function (data, dataType) {
  const v = data.value;
  if (v.length === 0) { // No data
    return 0;
  }
  // Expect a single 'Total' group in `data`
  if ((v.length !== 1) || (v[0].group !== 'Total')) {
    throw Error(`Unexpected result of '${dataType}'.`);
  }
  return v[0].value;
};

// Used for non-realtime garages to display data for day before last known day with data
Garages.getStartOfLatestDay = function (timezone, maxTime, bizStartHour) {
  const dayBeforeMaxTime = moment.utc(maxTime).subtract(bizStartHour, 'hours').toDate();
  const yesterday = Time.yesterday(timezone, bizStartHour).fromDate;
  const dateBeforeMaxTime = Time.bizDayStart(dayBeforeMaxTime, bizStartHour);
  return _.min([yesterday, dateBeforeMaxTime]);
};


Garages.dsPrefix = '/api/ds/v3/garages';

Garages.getData = require('./getData').getData;
Garages.getMultiyearData = require('./getMultiyearData').getMultiyearData;

Garages.getDataUrlForParams = function (params) {
  const requiredParams = ['garageId', 'timeType', 'dataType', 'fromDate', 'hours', 'granularity'];
  Assert.hasAll(params, requiredParams);

  const urlParams = _.clone(params);
  const {garageId, timeType, dataType, fromDate, hours, granularity} = urlParams;

  _.each(requiredParams, (requiredParam) => delete urlParams[requiredParam]);

  return Garages.getDataUrl(garageId, timeType, dataType, fromDate, hours, granularity, urlParams);
};

// callback: (profile?, errmsg?, resp)
Garages.createProfile = function (newProfile, callback) {
  const url = '/api/users/v1/garages';
  return Http.post(url, newProfile, {forceCallback: true}, callback);
};

// callback: (newGarages?, errmsg?, resp)
Garages.batchAddGarages = function (newProfiles, callback) {
  const url = '/api/users/v1/batch-add-garages';
  return Http.post(url, newProfiles, {forceCallback: true}, (conn, errmsg, resp) => Misc.attempt(callback, conn.garages, errmsg, resp));
};

// OBSOLETE, use Garages.updateProfileById
// callback: (success: Bool, errmsg?, resp)
Garages.updateGarageProfile = function (garageUrl, newProfile, callback) {
  const url = `/api/users/v1/garages/${garageUrl}`;
  return Http.put(url, newProfile, {forceCallback: true}, (conn, errmsg, resp) => Misc.attempt(callback, errmsg === undefined, errmsg, resp));
};

// callback: (success: Bool, errmsg?, resp)
Garages.updateProfileById = function (garageId, newProfile, callback) {
  const url = `/api/users/v1/garages/id/${garageId}`;
  return Http.put(url, newProfile, {forceCallback: true}, (conn, errmsg, resp) => Misc.attempt(callback, errmsg === undefined, errmsg, resp));
};

Garages.batchUpdateGarages = function (garages, callback) {
  const url = '/api/users/v1/batch-update-garages';
  return Http.put(url, garages, {forceCallback: true}, (conn, errmsg, resp) => Misc.attempt(callback, errmsg === undefined, errmsg, resp));
};

// callback: (success: Bool, errmsg?, resp)
Garages.deleteGarage = (garageId, callback) => Http.delete(`/api/users/v1/garages/id/${garageId}`, {forceCallback: true}, (conn, errmsg, resp) => Misc.attempt(callback, errmsg === undefined, errmsg, resp));


// Given historical & predicted data (both an array with granularity of hour),
// use predicted data to fill in empty spot in historical data.
//
Garages.mergeHistoricalAndPredictedData = function (histData, predData, apiFromDate, latestHistoricalDataDate) {
  if (histData.length !== predData.length) {
    throw Error('Historical and predicted data should be equal length!');
  }

  if (latestHistoricalDataDate < apiFromDate) { // Entirely prediction data
    return predData;
  }
  const histDataCount = moment(latestHistoricalDataDate).diff(apiFromDate, 'hour') + 1;

  if (histDataCount >= histData.length) { // All historical
    return histData;
  }
  return _.concat(histData.slice(0, histDataCount), predData.slice(histDataCount));
};

Garages.getProfileForDisplay = function (profile) {
  // Assign default value, otherwise they may be null
  let from;
  let
    to;
  let str;
  const profileClone = _.cloneDeep(profile); // Don't modify orig profile
  _.each(['name', 'address1', 'address2', 'city', 'state', 'zip', 'contact', 'contactEmail', 'contactPhone', 'contactPhoneType'], (attr) => profileClone[attr] || (profileClone[attr] = ''));

  const {
    name, spaces, bizStartHour,
    address1, address2, city, state, zip,
    contact, contactEmail, contactPhone, contactPhoneType,
  } = profileClone;


  const businessHour = (
    (from = moment(bizStartHour, 'hour')),
      (to = moment(bizStartHour, 'hour').subtract(1, 'minute')),
      `${from.format('h:mm A')} - ${to.format('h:mm A')}`
  );

  const contactPhoneStr = (() => {
    if (contactPhone.trim() === '') {
      return 'No contact phone';
    }
    str = Format.phone(contactPhone);
    if (contactPhoneType !== '') {
      str += ` (${_.capitalize(contactPhoneType)})`;
    }
    return str;
  })();

  const streetAddress = `${address1} ${address2}`.trim();

  const restAddress = _([city, `${state} ${zip}`])
    .map((str) => str.trim())
    .filter((str) => str !== '')
    .join(', ');

  const googleMapsAddress = `${streetAddress} ${restAddress}`.trim();

  return {
    name,
    spaces: `${Format.number(spaces)} spaces`,
    businessHour,
    address: {
      street: Format.strOrDefault(streetAddress, 'No street info'),
      rest: restAddress
    },
    contact: {
      name: Format.strOrDefault(contact, 'No contact name'),
      phone: contactPhoneStr,
      email: Format.strOrDefault(contactEmail, 'No contact email')
    },
    googleMapsAddress
  };
};

Garages.loadTransactionsCsv = function (garageId, dateRange, transactionsType, noLimit, callback) {
  const {from, to} = Time.dateRangeApiFromToDates2fromToIso(dateRange);
  Assert.includes(['processed', 'raw'], transactionsType);
  const dump_api = noLimit ? 'dump-all' : 'dump';
  const url = `/api/ds/v3/garages/${garageId}/${dump_api}/${transactionsType}-closed-transactions/between/${from}/${to}`;

  return Http.get(url, {forceCallback: true}, (transactions, errmsg, resp) => {
    if (errmsg) {
      SmkNotice.warning(errmsg, 'Failed to download transactions, time range is probably too long.', {expiration: 15});
      callback();
      return;
    }
    const csvStr = _convertTransactionsToCsv(transactions);
    return callback(csvStr);
  });
};

Garages.loadOpenTransactionsCsv = function (garageId, callback) {
  const url = `/api/ds/v3/garages/${garageId}/dump/open-transactions-for-location`;
  return Http.get(url, {forceCallback: true}, (transactions, errmsg, resp) => {
    if (errmsg) {
      SmkNotice.warning(errmsg, 'Failed to download open transactions.', {expiration: 15});
      callback();
      return;
    }
    const csvStr = _convertTransactionsToCsv(transactions);
    return callback(csvStr);
  });
};


var _convertTransactionsToCsv = function (transactions, callback) {
  // transactions: { columns, data }
  //   columns: [ columnName ]
  //   data: [ transaction ]
  //     transaction: [ columnValue ]

  const {columns, data} = transactions;
  const allRows = _.concat([columns], data);

  return Format.generateCsvForExcel(allRows);
};


// Routes for the project in the "from/hours/unit" format.
Garages.getDataUrl = function (garageId, timeType, dataType, fromDate, hours, granularity, queries) {
  let url;
  if (queries == null) {
    queries = {};
  }
  const allowedDataTypes = ['occupancy', 'entries', 'exits', 'revenue', 'transactions', 'duration'];
  _verifyParams(garageId, timeType, dataType, allowedDataTypes);
  const from = Time.namedFormat(fromDate, 'iso');
  const baseUrl = timeType === 'past'
    ? `${Garages.dsPrefix}/${garageId}/${timeType}/${dataType}/from/${from}/${hours}/${granularity}`
    : // Temporary prediction route.
    `${Garages.dsPrefix}/${garageId}/${timeType}/unicorn-temporary/${dataType}/from/${from}/${hours}`;
  return url = URI(baseUrl).query(queries).toString();
};

Garages.getPredictionAtUrl = function (garageId, dataType, targetTime, queries) {
  let url;
  if (queries == null) {
    queries = {};
  }
  const allowedDataTypes = ['occupancy', 'entries', 'exits'];
  const target = Time.namedFormat(targetTime, 'iso');
  const baseUrl = `${Garages.dsPrefix}/${garageId}/future/unicorn-temporary/${dataType}/at/${target}`;
  return url = URI(baseUrl).query(queries).toString();
};

Garages.getUrlInBetweenFormat = function (garageId, timeType, dataType, start, end, queries) {
  if (queries == null) {
    queries = {};
  }
  if (dataType == 'revenue') {
    queries = {
      ...queries,
      currency: Localization.getCurrencyCode()
    };
  }
  const allowedDataTypes = ['occupancy-breakdown/maximum', 'transactions', 'revenue', 'duration', 'average-occupancy'];
  _verifyParams(garageId, timeType, dataType, allowedDataTypes);
  const startDate = moment(start).startOf('hour').toDate();
  const endDate = moment(end).startOf('hour').toDate();
  const [startTime, endTime] = Array.from(_.map([startDate, endDate], (t) => Time.namedFormat(t, 'iso')));
  const baseUrl = timeType === 'future'
    ? `${Garages.dsPrefix}/${garageId}/${timeType}/unicorn-temporary/${dataType}/between/${startTime}/${endTime}`
    : `${Garages.dsPrefix}/${garageId}/${timeType}/${dataType}/between/${startTime}/${endTime}`;
  return URI(baseUrl).query(queries).toString();
};

var _verifyParams = function (garageId, timeType, dataType, allowedDataTypes) {
  Assert.validGarageId(garageId);
  const ALLOWED_TIME_TYPES = ['past', 'future'];
  Assert.includes(ALLOWED_TIME_TYPES, timeType, 'timeType');
  return Assert.includes(allowedDataTypes, dataType, 'dataType');
};


Garages.isDimensionCacheReady = function (selectedDimension, dimensions) {
  const dimension = _.find(dimensions, {name: selectedDimension});
  return (dimension != null ? dimension.cacheReady : undefined) === true;
};


//= ===============================================================================
// Data formatting
//= ===============================================================================
// resp: { value: [ group ] }
//   group: { group: groupName, value: [ points ] }
// ==>
// result: [ group ], sorted by group.group
//   group is same as in resp.
Garages.formatResponse = function (resp) {
  if (!_.isArray(resp.value)) {
    throw Error(`Unexpected response format: ${resp}.`);
  }
  return _.sortBy(resp.value, 'group');
};


// groups: [ group ]
//   group: { group: groupName, value: [ val ] }
// =>
// result: [ group ]
//   group: { name: groupName, values: [ val ] }
//
Garages.convertGroupsFormat = (groups) => _.map(groups, Garages.convertGroupFormat);


// group: { group: groupName, value: [ val ] }
// =>
// result: { name: groupName, values: [ val ] }
Garages.convertGroupFormat = (g) => ({
  name: g.group,
  values: g.value
});


Garages.initialHomeDimension = {
  name: 'User Type',
  sql: 'user_type',
  cacheReady: false,
  useCalibrator: false
};


Garages.withoutLocationData = function (garage) {
  const locationFieldNames = [
    'address1', 'address2', 'city', 'state', 'zip',
    'latitude', 'longitude'
  ];

  return _.omit(garage, locationFieldNames);
};


Garages.prettyAddress = (g) => _.compact([g.address1, g.address2, g.city, g.state]).join(', ') + (g.zip ? ' ' + g.zip : '');
